import {useEffect} from 'react';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {type ConnectDragPreview} from 'react-dnd';

export const useDndEmptyImage = (
  preview?: ConnectDragPreview,
  captureDraggingState: boolean = true
) => {
  useEffect(() => {
    preview && preview(getEmptyImage(), {captureDraggingState});
  }, [captureDraggingState, preview]);
};
