import React, {type FC} from 'react';
import classNames from 'classnames';

import './DragHandle.scss';

const SVG: FC<{disabled: boolean}> = ({disabled}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="14"
    viewBox="0 0 6 14"
    className={classNames('handle', {disabled})}
  >
    <path
      fillRule="evenodd"
      d="M1,4 C1.55228475,4 2,4.44771525 2,5 C2,5.55228475 1.55228475,6 1,6 C0.44771525,6
        6.76353751e-17,5.55228475 0,5 C-6.76353751e-17,4.44771525 0.44771525,4 1,4 Z M1,8 C1.55228475,8 2,8.44771525
        2,9 C2,9.55228475 1.55228475,10 1,10 C0.44771525,10 6.76353751e-17,9.55228475 0,9 C-6.76353751e-17,8.44771525
        0.44771525,8 1,8 Z M1,12 C1.55228475,12 2,12.4477153 2,13 C2,13.5522847 1.55228475,14 1,14 C0.44771525,14
        6.76353751e-17,13.5522847 0,13 C-6.76353751e-17,12.4477153 0.44771525,12 1,12 Z M5,4 C5.55228475,4 6,4.44771525
        6,5 C6,5.55228475 5.55228475,6 5,6 C4.44771525,6 4,5.55228475 4,5 C4,4.44771525 4.44771525,4 5,4 Z M1,0
        C1.55228475,-1.01453063e-16 2,0.44771525 2,1 C2,1.55228475 1.55228475,2 1,2 C0.44771525,2 6.76353751e-17,
        1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z M5,0 C5.55228475,-1.01453063e-16
        6,0.44771525 6,1 C6,1.55228475 5.55228475,2 5,2 C4.44771525,2 4,1.55228475 4,1 C4,0.44771525 4.44771525,
        1.01453063e-16 5,0 Z M5,8 C5.55228475,8 6,8.44771525 6,9 C6,9.55228475 5.55228475,10 5,10 C4.44771525,10
        4,9.55228475 4,9 C4,8.44771525 4.44771525,8 5,8 Z M5,12 C5.55228475,12 6,12.4477153 6,13 C6,13.5522847
        5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 C4,12.4477153 4.44771525,12 5,12 Z"
    />
  </svg>
);

interface Props {
  useSVGHack?: boolean;
  disabled?: boolean;
}

export const DragHandle: FC<Props> = ({useSVGHack = false, disabled = false}) =>
  useSVGHack ? (
    <span className="svg-wrapper">
      <SVG disabled={disabled} />
      <span className="char">{'\uFEFF'}</span>
    </span>
  ) : (
    <SVG disabled={disabled} />
  );

export default React.memo<Props>(DragHandle);
