import React, {type FC, memo} from 'react';
import {useDragLayer, type XYCoord} from 'react-dnd';
import {Portal} from 'react-portal';

import {type DndComponentProps} from 'routes/Library/XEditorPage/components/XEditor/RenderDragItem';

import './DragLayer.scss';
import {type DndTypes, type DragObject} from './interface';

interface Props {
  renderItem: (
    itemType: DndTypes | null,
    item: DndComponentProps,
    style: React.CSSProperties
  ) => JSX.Element | null;
}

function getItemStyles(
  currentOffset: XYCoord | null,
  startOffset: XYCoord | null,
  dndElementRect?: DOMRect
) {
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const {x, y} = currentOffset;

  if (!dndElementRect || !startOffset) {
    const transform = `translate(${Math.round(x)}px, ${Math.round(y)}px)`;
    return {
      transform,
      WebkitTransform: transform
    };
  }

  const rangeToHandleX = startOffset.x - dndElementRect.left;
  const transform = `translate(${Math.round(x - rangeToHandleX)}px, ${Math.round(y)}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

export const DragLayer: FC<Props> = memo(({renderItem}) => {
  const {isDragging, item, itemType, currentOffset, startOffset} = useDragLayer(monitor => {
    const item: DragObject = monitor.getItem();

    return {
      item,
      itemType: monitor.getItemType() as DndTypes,
      isDragging: monitor.isDragging(),
      startOffset: monitor.getInitialClientOffset(),
      currentOffset: item?.considerClientOffset
        ? monitor.getClientOffset()
        : monitor.getSourceClientOffset()
    };
  });

  if (!isDragging || item.isNotUseDragLayer) {
    return null;
  }
  return (
    <Portal>
      <div className="x-drag-layer">
        {renderItem(itemType, item, getItemStyles(currentOffset, startOffset, item?.itemRect))}
      </div>
    </Portal>
  );
});
