export enum DndTypes {
  SCRAMBLED_SENTENCES_CARD = 'SCRAMBLED_SENTENCES_CARD',
  UNIT_EXERCISE = 'UNIT_EXERCISE',
  IMAGE_MATCHING = 'IMAGE_MATCHING',
  IMAGE_LABELING = 'IMAGE_LABELING',
  PICTURE_SET = 'PICTURE_SET',
  WORD_PICTURE_SET = 'WORD_PICTURE_SET',
  PICTURE_CHOICE = 'PICTURE_CHOICE',
  FLIP_CARDS = 'FLIP_CARDS',
  UNIT_ITEM = 'UNIT_ITEM',
  UNIT_PAGE = 'UNIT_PAGE',
  EXERCISE_MEDIA_SOURCE = 'EXERCISE_MEDIA_SOURCE',
  // todo: mb change type name, bc it is used not only in gaps, but also in matching and image-matching widgets
  X_GAP = 'X-GAP',
  XVOCABULARY_ENTRY = 'XVOCABULARY-ENTRY',
  COURSEBOOK_SECTION = 'COURSEBOOK_SECTION',
  ASSESSMENT_ITEM = 'ASSESSMENT_ITEM',
  SPELLING_CARD = 'SPELLING_CARD'
}

export interface DragObject {
  considerClientOffset?: boolean;
  itemRect?: DOMRect;
  isNotUseDragLayer?: boolean;
}
